$primary-clr: #d69a00;
$secondary-clr: #303030;
html {
  scroll-behavior: smooth;
}
*{
  outline: none;
  border: none;
}
body{
    font-family:helvetica !important;
    margin: 0 auto;
    color: #000;
}
h1, h2, h3, h4, h5, h6, p, figure{
    margin: 0;
}
li{
    list-style: none;
}
ul{
    padding: 0;
}
.conta_iner{
    width: 95%;
    margin: auto;
    
}
.verifyCls{
  width: 700px !important;
  position: relative !important;
  @media(max-width:767px){
    width: 100% !important;
  }
}
.wrap{
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: hidden;
}
img{width:100%;}
.slick-prev:before, .slick-next:before{
  color: #12847db0 !important;
}
.slick-prev:before, .slick-next:before {
  font-size: 32px !important;
}
.slick-prev, .slick-next {
  height: 40px!important;
  width: 40px!important;
}
@media (max-width:767px){
.conta_iner{
  width: 90%;
}
    
  
}
@font-face {
    font-family: helvetica;
    src: url(assets/font/helvetica.ttf);
  }
  @font-face {
    font-family: 'helveticabold';
    src: url(assets/font/helveticabold.ttf);
  }

  @font-face {
    font-family: 'cormorantGaramondbold';
    src: url(assets/font/cormorantGaramondbold.ttf);
  }

  @font-face {
    font-family: 'cormorantGaramondsemiBold';
    src: url(assets/font/cormorantGaramondsemiBold.ttf);
  }